
// Loader
export const START_LOADER = "START_LOADER"
export const STOP_LOADER = "STOP_LOADER"
export const SET_SIDE_BAR = "SET_SIDE_BAR"

// Payment page
export const DONATE_PAYMENT = "DONATE_PAYMENT"
export const ONE_TIME_ANONYMOUS_PAYMENT = "ONE_TIME_ANONYMOUS_PAYMENT"
export const SUBSCRIPTION_PAYMENT = "SUBSCRIPTION_PAYMENT"
export const VERIFY_BONUS_CODE = "VERIFY_BONUS_CODE"

// Tickets or games
export const GET_TICKETS = "GET_TICKETS"
export const SET_TICKETS = "SET_TICKETS"
export const SEND_RAFFLE_TICKETS = "SEND_RAFFLE_TICKET"
export const GET_TICKETS_BY_ID = "GET_TICKETS_BY_ID"
export const SET_RAFFLE_TICKETS = "SET_RAFFLE_TICKETS"
export const GET_RAFFLE_TICKETS = "GET_RAFFLE_TICKETS"

// Past winners
export const GET_PAST_WINNERS = "GET_PAST_WINNERS"
export const GET_PAST_WINNERS_BY_ID = "GET_PAST_WINNERS_BY_ID"

// Email Verification
export const VERIFY_EMAIL = "VERIFY_EMAIL"

// Blogs
export const GET_BLOGS = "GET_BLOGS"
export const GET_BLOGS_BY_ID = "GET_BLOGS_BY_ID"
export const GET_BLOGS_CATEGORY = "GET_BLOGS_CATEGORY"

// Contact us 
export const CONTACT = "CONTACT"