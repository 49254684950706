import { all, fork } from "redux-saga/effects";

import watchAuth from "./Auth";
import watchPaymentPage from "./PaymentPage";
import watchTickets from "./Tickets";
import watchBlogs from "./Blogs";
import watchContact from "./Contact";

function* rootSaga() {
  yield all([
    fork(watchAuth),
    fork(watchPaymentPage),
    fork(watchTickets),
    fork(watchBlogs),
    fork(watchContact),
  ]);
}

export default rootSaga;
