import { LABELS } from "Shared/Constants";
import { Images } from "Shared/Images";
import React from "react";

const CommonMiniHeartsHeading = () => {
  return (
    <>
      <div className="miniHeartLogo">
        <img
          className="img-fluid my-cursor-pointer"
          width={"180px"}
          src={Images?.miniHeartFoundation}
          alt="deal_image_cap"
          onClick={() =>
            window.open(process.env.REACT_APP_DOMAIN_MINIHEARTS_ORG, "_self")
          }
        />
      </div>
    </>
  );
};

export default CommonMiniHeartsHeading;
