import { SET_RAFFLE_TICKETS, SET_TICKETS } from "Redux/Actions/ActionTypes";

const initialState={
    activeTickets: null,
    raffleHeartTickets: [],
}

function ticketReducer(state=initialState,action){
 switch(action.type){

    case SET_TICKETS:
        return {
            ...state,
            activeTickets: action.payload
        }
    case SET_RAFFLE_TICKETS:
        return {
            ...state,
            raffleHeartTickets: [...state?.raffleHeartTickets, ...action?.payload]
        }

    default:
        return state;
 }
}

export default ticketReducer;