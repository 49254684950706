import {
  GET_PAST_WINNERS,
  GET_PAST_WINNERS_BY_ID,
  GET_TICKETS,
  GET_TICKETS_BY_ID,
} from "Redux/Actions/ActionTypes";
import { startLoader, stopLoader } from "Redux/Actions/Loader";
import { setTickets } from "Redux/Actions/Tickets";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { getRequestNoAuthLuxe } from "Shared/Axios";
import { all, put, takeLatest } from "redux-saga/effects";

function* getTicketRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield getRequestNoAuthLuxe({
      API:
        API_URLS.GET_TICKETS +
        `?sortKey=eventTime&sortDirection=1${
          payload?.limit ? `&limit=${payload?.limit}` : ""
        }${payload?.skip ? `&skip=${payload?.skip}` : ""}${
          payload?.search ? `&searchkey=${payload?.search}` : ""
        }${payload?.date ? `&date=${payload?.date}` : ""}`,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload?.success(data?.data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* getTicketByIdRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield getRequestNoAuthLuxe({
      API: API_URLS.GET_TICKETS + `?gameId=${payload?.gameId}`,
    });

    if (status === STATUS_CODES.SUCCESS) {
      console.log(data);
      if (payload?.success) {
        payload?.success(data?.data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.message);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* getPastWinnersRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield getRequestNoAuthLuxe({
      API:
        API_URLS.GAME_WITH_WINNER_TICKETS +
        `?${payload?.limit ? `&limit=${payload?.limit}` : ""}${
          payload?.skip ? `&skip=${payload?.skip}` : ""
        }${payload?.search ? `&searchkey=${payload?.search}` : ""}${
          payload?.fromDate ? `&fromDate=${payload?.fromDate}` : ""
        }${payload?.toDate ? `&toDate=${payload?.toDate}` : ""}`,
    });

    if (status === STATUS_CODES.SUCCESS) {
      console.log(data);
      if (payload?.success) {
        payload?.success(data?.data);
      }
    }
  } catch (error) {
    console.log(error, "khuigui");
    if (payload?.fail) {
      payload?.fail(error?.data?.msg);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* getPastWinnersByIdRequest({ payload }) {
  try {
    yield put(startLoader());
    const { data, status } = yield getRequestNoAuthLuxe({
      API:
        API_URLS.GAME_WITH_WINNER_TICKETS +
        `?gameId=${payload.gameId}${
          payload?.limit ? `&limitWinners=${payload?.limit}` : ""
        }${payload?.skip ? `&skipWinners=${payload?.skip}` : ""}${
          payload?.searchkey ? `&searchkey=${payload?.searchkey}` : ""
        }${payload?.fromDate ? `&fromDate=${payload?.fromDate}` : ""}${
          payload?.toDate ? `&toDate=${payload?.toDate}` : ""
        }`,
    });

    if (status === STATUS_CODES.SUCCESS) {
      if (payload?.success) {
        payload?.success(data?.data);
      }
    }
  } catch (error) {
    console.log(error);
    if (payload?.fail) {
      payload?.fail(error?.data?.msg);
    }
  } finally {
    yield put(stopLoader());
  }
}

function* watchTickets() {
  yield all([
    takeLatest(GET_PAST_WINNERS_BY_ID, getPastWinnersByIdRequest),
    takeLatest(GET_TICKETS_BY_ID, getTicketByIdRequest),
    takeLatest(GET_TICKETS, getTicketRequest),
    takeLatest(GET_PAST_WINNERS, getPastWinnersRequest),
  ]);
}

export default watchTickets;
