// routes
import { ROUTE_CONSTANTS } from "Shared/Routes";
import ComingSoon from "Views/ComingSoon";

// components

// *Moved: Removed from this panel and moved to MiniHearts_Main_Page (Landing Page)
export const PUBLIC_ROUTES = [
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <ComingSoon />,
    title: "Coming Soon"
  }
  // {
  //   path: ROUTE_CONSTANTS.LANDING_PAGE,
  //   element: <LandingPage />,
  //   title: "Give2thekids",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.PAYMENT_PAGE,
  //   element: <PaymentPage />,
  //   title: "Payment Page",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE,
  //   element: <PrivacyPolicy />,
  //   title: "Privacy Policy",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.TERM_AND_CONDITIONS,
  //   element: <TermsConditions />,
  //   title: "Terms & Conditions",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.CONTACT_US,
  //   element: <ContactUs />,
  //   title: "Contact Us",
  // },
  // {
  //   path: ROUTE_CONSTANTS.FUTURE_DRAWS,
  //   element: <FutureDraws />,
  //   title: "Future Draws",
  // },
  // {
  //   path: ROUTE_CONSTANTS.PAST_WINNERS,
  //   element: <PastWinners />,
  //   title: "Past Winners",
  // },
  // {
  //   path: ROUTE_CONSTANTS.GIVEAWAY,
  //   element: <Giveaway />,
  //   title: "Giveaway",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.SUCCESS_DONATION,
  //   element: <SuccessDonation />,
  //   title: "Success Donation",
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.FAILED_DONATION,
  //   element: <FailedDonation />,
  //   title: "Failed Donation",
  // },
  // {
  //   path: ROUTE_CONSTANTS.TICKET_PAST_WINNERS,
  //   element: <CurrentTicketWinner />,
  //   title: "Current Ticket Winners"
  // },
  // {
  //   path: ROUTE_CONSTANTS.GIVE2THEKIDS_BLOGS,
  //   element: <Blogs />,
  //   title: "Blogs"
  // },
  // {
  //   path: ROUTE_CONSTANTS.GIVE2THEKIDS_BLOG_DETAILS,
  //   element: <BlogDetail />,
  //   title: "Blog Detail"
  // },
  // // Moved 
  // {
  //   path: ROUTE_CONSTANTS.MAINTENANCE,
  //   element: <Maintenance />,
  //   title: "Maintenance"
  // }
];
