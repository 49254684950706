export const ROUTE_CONSTANTS = {
    HOME: "/",
    LANDING_PAGE: "/mini-landing-page",
    PAYMENT_PAGE: "/payment",
    PRIVACY_POLICY_PAGE: "/privacy-policy",
    TERM_AND_CONDITIONS: "/term-and-conditions",
    CONTACT_US: "/contact-us",
    FUTURE_DRAWS: "/future-draws",
    PAST_WINNERS: "/past-winners",
    GIVEAWAY: "/giveaway",
    SUCCESS_DONATION: "/success-donation",
    FAILED_DONATION: "/failed-donation",
    TICKET_PAST_WINNERS: "/ticket-past-winners",
    GIVE2THEKIDS_BLOGS: "/give2thekids-blogs",
    GIVE2THEKIDS_BLOG_DETAILS: "/give2thekids-blog-detail",
    MAINTENANCE: "/maintenance",
}