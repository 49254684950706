// libs
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";

// store
import { store, persistor } from "./Redux/Store";

// config
import { SnackbarUtilsConfigurator } from "Shared/SnackbarUtilConfig";

// components
import Loader from "Components/Core/Loader";
import RootRouter from "./Routes/RootRouter";

// styles
import './App.scss';
import MyChatComponent from "Components/Atom/MyChatComponent";

function App() {
  return (
    <SnackbarProvider
		maxSnack={3}
		autoHideDuration={2000}
		anchorOrigin={{ horizontal: "top", vertical: "center" }}
		className="mt-5"
    >
    	<Provider store={store}>
			<SnackbarUtilsConfigurator />
			{/* <MyChatComponent /> */}
			<Loader />
			<PersistGate persistor={persistor}>
				<RootRouter />
			</PersistGate>
		</Provider>
    </SnackbarProvider>
  );
}

export default App;