import { SET_SIDE_BAR, START_LOADER, STOP_LOADER } from "Redux/Actions/ActionTypes";

const initialState={
    loading: false,
    sidebar: false,
}

function loaderReducer(state=initialState,action){
 switch(action.type){

    case START_LOADER:
        return {
            ...state,
            loading: true
        }
     case STOP_LOADER:
        return {
            ...state,
            loading: false
        }
    case SET_SIDE_BAR:
      return {
        ...state,
        sidebar: action.payload,
      };

    default:
        return state;
 }
}

export default loaderReducer;