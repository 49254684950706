
import Louis_1 from "../Assets/Images/Louis-Vuitton-Brown-Bag-PNG-Photo 1.png";
import banner_image from "../Assets/Images/banner_image.png";
import CasinoImg from "../Assets/Images/blogs/cassino.jpg";
import childhood_image from "../Assets/Images/childhood_image.png";
import circle_svg_shape from "../Assets/Images/circle_svg_shape.png";
import DonateNow from "../Assets/Images/donate-now.svg";
import past_draw from "../Assets/Images/future_draw.png";
import give_kids from "../Assets/Images/give_kids.png";
import giveaway from "../Assets/Images/giveaway.png";
import HeroImage from "../Assets/Images/hero_image_girl.jpg";
import arrowButton from "../Assets/Images/icons/arrow_bottom.svg";
import badgeSvg from "../Assets/Images/icons/badge.svg";
import BlogIcon from "../Assets/Images/icons/blog.svg";
import CalenderIcon from "../Assets/Images/icons/calendar-clock.svg";
import CrossIcon from "../Assets/Images/icons/cross_icon.svg";
import deleteSvg from "../Assets/Images/icons/delete.svg";
import donateIcon from "../Assets/Images/icons/donateIcon.svg";
import editSvg from "../Assets/Images/icons/edit.svg";
import LiveChat from "../Assets/Images/icons/live_chat.svg";
import MiniSvg from "../Assets/Images/icons/miniheart-logo.svg";
import paw from "../Assets/Images/icons/paw.svg";
import pawWhite from "../Assets/Images/icons/paw_white.svg";
import searchIcon from "../Assets/Images/icons/search-icon.svg";
import shieldCheck from "../Assets/Images/icons/shield-check.svg";
import ticketCard from "../Assets/Images/icons/ticket_card.svg";
import miniHeartFoundation from "../Assets/Images/mini-hearts-logo.png";
import mini_heart_logo from "../Assets/Images/mini-logo.png";
import future_draw from "../Assets/Images/past_draw.png";
import DiamondHeart from "../Assets/Images/payment/diamond-heart-shape.svg";
import GoldHeart from "../Assets/Images/payment/gold-heart-shape.svg";
import StripeImage from "../Assets/Images/payment/payment-stripe.svg";
import RedHeart from "../Assets/Images/payment/red-heart-shape.svg";
import Signature from "../Assets/Images/payment/signature.svg";
import SilverHeart from "../Assets/Images/payment/silver-heart-shape.svg";
import SuccessImg from "../Assets/Images/payment/success-img.svg";
import ServerError from "../Assets/Images/server-error.svg";
import successLogo from "../Assets/Images/success-logo.png";
import thankyou_image from "../Assets/Images/thankyou_image.png";
import thankyou from "../Assets/Images/thankyougirl.png";
import the_rule_image from "../Assets/Images/the_rule_image.png";

export const Images = {
    ServerError,
    BlogIcon,
    CasinoImg,
    CalenderIcon,
    CrossIcon,
    Signature,
    SuccessImg,
    ticketCard,
    badgeSvg,
    giveaway,
    future_draw,
    past_draw,
    pawWhite,
    give_kids,
    miniHeartFoundation,
    editSvg,
    deleteSvg,
    MiniSvg,
    donateIcon,
    searchIcon,
    thankyou,
    successLogo,
    RedHeart,
    SilverHeart,
    GoldHeart,
    DiamondHeart,
    StripeImage,
    DonateNow,
    paw,
    the_rule_image,
    arrowButton,
    childhood_image,
    mini_heart_logo,
    circle_svg_shape,
    Louis_1,
    thankyou_image,
    banner_image,
    shieldCheck,
    LiveChat,
    HeroImage
}