import { GET_RAFFLE_TICKETS, ONE_TIME_ANONYMOUS_PAYMENT, SEND_RAFFLE_TICKETS, SUBSCRIPTION_PAYMENT, VERIFY_BONUS_CODE, VERIFY_EMAIL } from "Redux/Actions/ActionTypes"
import { startLoader, stopLoader } from "Redux/Actions/Loader";
import { setRaffleTickets } from "Redux/Actions/PaymentPage";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { postRequestNoAuthLuxe } from "Shared/Axios";
import { getRequest, postRequest } from "Shared/Axios";
import { all, put, takeLatest } from "redux-saga/effects"

function* oneTimeAnonymousPaymentRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield postRequest({
			API: API_URLS.ONE_TIME_ANONYMOUS_PAYMENT ,
			DATA: payload?.formData
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			const {data: data2, status: statusTwo} = yield postRequestNoAuthLuxe({
				API: API_URLS.EMAIL_RAFFLE_TICKET,
				DATA: payload?.formData2,
			});
			console.log(data2);
			if (statusTwo===STATUS_CODES.SUCCESS) {
				if (payload?.success) {
					payload?.success(data?.data)
				}
			}
		}
    } catch (error) {
		console.log(error);
		// debugger;
		if (payload?.fail) {
			payload?.fail(error?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* subscriptionPaymentRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield postRequest({
			API: API_URLS.SUBSCRIPTION_PAYMENT ,
			DATA: payload?.formData
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			const {data: data2, status: statusTwo} = yield postRequestNoAuthLuxe({
				API: API_URLS.EMAIL_RAFFLE_TICKET,
				DATA: payload?.formData2,
			});
			console.log(data2);
			if (statusTwo===STATUS_CODES.SUCCESS) {
				if (payload?.success) {
					payload?.success(data?.data)
				}
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* getRaffleTicketsRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield getRequest({
			API: API_URLS.GET_RAFFLE_TICKET+`?limit=${payload?.limit}&skip=${payload?.skip}` ,
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			yield put(setRaffleTickets(data?.data?.raffleTickeInfo))
			if (payload?.success) {
				payload?.success(data?.data)
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* sendRaffleTicketsRequest({payload}) {
    try {
		yield put(startLoader())
		const {data, status} = yield postRequest({
			API: API_URLS.EMAIL_RAFFLE_TICKET,
			DATA: payload?.formData,
		});
	
		if(status===STATUS_CODES.SUCCESS){
			console.log(data)
			if (payload?.success) {
				payload?.success(data?.data)
			}
		}
    } catch (error) {
		console.log(error);
		if (payload?.fail) {
			payload?.fail(error?.data?.message);
		}
    }
    finally{
      	yield put(stopLoader())
    }
}

function* verifyEmailRequest({payload}) {
	try {
	  // yield put(startLoader())
	  const {data, status} = yield getRequest({
		API: API_URLS.CHECK_EMAIL+`${payload?.email ? `?email=${payload?.email}`: ""}${payload?.phone ? `?phone=${encodeURIComponent(payload?.phone)}`: ""}`,
	  });
  
	  if(status===STATUS_CODES.SUCCESS ){
		console.log(data)
		if (payload?.success) {
		  payload?.success(data?.data)
		}
	  }
	
	} catch (error) {
	  console.log(error, error?.status);
	  if (payload?.fail) {
		payload?.fail(error?.response?.data?.message);
	  }
	}
	finally{
	  // yield put(stopLoader())
	}
}

function* verifyBonusCodeRequest({payload}) {
	try {
	  yield put(startLoader())
	  const {data, status} = yield getRequest({
		API: API_URLS.VALIDATE_BONUS_CODE+`?referralCode=${payload?.referralCode}&email=${payload?.email}`,
	  });
  
	  if(status===STATUS_CODES.SUCCESS ){
		console.log(data)
		if (payload?.success) {
		  payload?.success(data?.data)
		}
	  }
	
	} catch (error) {
	  console.log(error, error?.status);
	  if (payload?.fail) {
		payload?.fail(error?.response?.data?.message);
	  }
	}
	finally{
	  yield put(stopLoader())
	}
}

function* watchPaymentPage() {
    yield all([
		takeLatest(VERIFY_BONUS_CODE, verifyBonusCodeRequest),
		takeLatest(SEND_RAFFLE_TICKETS, sendRaffleTicketsRequest),
		takeLatest(GET_RAFFLE_TICKETS, getRaffleTicketsRequest),
		takeLatest(SUBSCRIPTION_PAYMENT, subscriptionPaymentRequest),
        takeLatest(ONE_TIME_ANONYMOUS_PAYMENT, oneTimeAnonymousPaymentRequest),
		takeLatest(VERIFY_EMAIL, verifyEmailRequest)
    ])
}

export default watchPaymentPage