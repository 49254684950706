// actions

// constants

// libs

// components
import CommonMiniHeartsHeading from 'Components/Atom/CommonMiniHeartsHeading';

import { Images } from 'Shared';
import './server.scss';

const Maintenance = () => {

	return (
		<>
			<section className="server_secn py-5">
				<div className="serverImg text-center">
					<CommonMiniHeartsHeading />
					<img src={Images.ServerError} alt='server eror' width="800" />
				</div>
			</section>
		</>
	)
}

export default Maintenance