import { SET_SIDE_BAR, START_LOADER, STOP_LOADER } from "./ActionTypes"

export const startLoader = () => {
    return {
        type: START_LOADER,
    }
}

export const stopLoader = () => {
    return {
        type: STOP_LOADER,
    }
}

export const setSideBar = (payload) => {
    return {
        type: SET_SIDE_BAR,
        payload,
    }
}

