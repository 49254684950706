import { DONATE_PAYMENT, GET_RAFFLE_TICKETS, ONE_TIME_ANONYMOUS_PAYMENT, SEND_RAFFLE_TICKETS, SET_RAFFLE_TICKETS, SUBSCRIPTION_PAYMENT, VERIFY_BONUS_CODE, VERIFY_EMAIL } from "./ActionTypes"

export const donatePayment = (payload) =>{
    return{
        type: DONATE_PAYMENT,
        payload,
    }
}

export const oneTimeAnonymousPayment = (payload) => {
    return {
        type: ONE_TIME_ANONYMOUS_PAYMENT,
        payload,
    }
}

export const subscriptionPayment = (payload) => {
    return {
        type: SUBSCRIPTION_PAYMENT,
        payload,
    }
}

export const getRaffleTickets = (payload) => {
    return {
        type: GET_RAFFLE_TICKETS,
        payload
    }
}

export const setRaffleTickets = (payload) => {
    return {
        type: SET_RAFFLE_TICKETS,
        payload
    }
}

export const sendRaffleTickets = (payload) => {
    return {
        type: SEND_RAFFLE_TICKETS,
        payload
    }
}


export const verifyEmail = (payload) => {
    return {
        type: VERIFY_EMAIL,
        payload
    }
}  

export const verifyBonusCode = (payload) => {
    return {
        type: VERIFY_BONUS_CODE,
        payload
    }
}