import { ROUTE_CONSTANTS } from "./Routes";

export const DONATION_PAGE_URL = process.env.REACT_APP_DOMAIN_MINI_DONATION_PAGE;

export const accountType = {
  admin: 1,
  partner: 2,
  company: 3,
  individual: 4,
  story: 5,
};

export const STRINGS = {
  LABELS: {
    START_DATE: "Start date",
    END_DATE: "End date",
    GIVE2_THE_KIDS_NEWS: "Give2TheKids News",
    BLOGS: "Blogs",
    SEARCH: "Search",
    FOR_INDIVIDUALS: "For individuals",
    HOW_IT_WORKS: "How it works",
    SIGN_UP_MESSAGE: "Sign In/ Become a Member",
    LOGIN: "Login",
    LOGOUT: "Logout",
    SUBMIT_YOUR_STORY: "Submit Your Story",
    TRENDING: "Trending",
    SEE_ALL: "See All",
    SEE_MORE: "See More",
    DONATION_FEQUENCY_TEXT: "Select Your Donation Frequency",
    USER_DETAILS: "User Details",
    PAYMENT_METHOD: "Payment Method",
    POWERED_BY_STRIPE: "Powered By Stripe",
    CARD_DETAILS: "Card Details",
    CHANGING_CHILDREN_LIVE_MESSAGE:
      "Changing Children's Live With Every Ticket Donated",
    ON_BEHALF_KIDS: "On behalf of the Kids,",
    THANKYOU_WINNER: "We Thank You you are already a Winner.",
    START_DONATION_CHARITY: "START DONATE ON OUR CHARITY",
    GENERATE_RAFFLE_MESSAGE:
      "When you donate to Mini Hearts, Give2TheKids automatically sends you tickets to the next sweepstakes",
    FUTURE_DRAWS: "Future Draws",
    PAST_WINNERS: "Past Winner",
    TICKER_NUMBER: "Ticket Number",
    PRIVACY_POLICY: "Privacy Policy",
    SEE_PRIZE: "See Prize Showcase",
    DONATE_NOW: "Donate Now",
    CHAT_WITH_US: "Chat With Us",
    TERMS_AND_CONDITION: "Terms & Conditions",
    CONTACT_US: "Contact",
  },

  ERROR_STRING: {
    NO_NEWS: "There is no news to share currently",
    NEXT_SWEEP: "Next Sweepstakes Coming Soon",
    SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
    OFFLINE_MESSAGE:
      "You appear to be offline. Please check your internet connection.",
    FIELD_REQUIRED: "Field is required",
    NO_DATA_FOUND: "No data is available",
    PHONE_NUMBER_LENGTH_CHECK: "Phone number must be greater than ",
  },

  CURRENCIES: {
    AUD: "$",
    EUR: "€",
    INR: "₹",
    GBP: "£",
    USD: "$",
  },

  CURRENCIES_LABEL: {
    AUD: "AUD",
    EUR: "EUR",
    INR: "INR",
    GBP: "GBP",
    USD: "USD",
  },

  NUMBER: {
    ZERO: "0",
    ONE: "1",
    TWO: "2",
    THREE: "3",
    FOUR: "4",
    FIVE: "5",
    SIX: "6",
    SEVEN: "7",
    EIGHT: "8",
    NINE: "9",
    TEN: "10",
    ELEVEN: "11",
    TWELVE: "12",
    THIRTEEN: "13",
    BILLION: "1000000000",
  },
  COMING_SOON: "Give2TheKids sweepstakes draws coming soon"
};

export const HEADER_TAB = [
  {
    label: STRINGS.LABELS.FUTURE_DRAWS,
    value: "1",
    path: ROUTE_CONSTANTS.FUTURE_DRAWS,
  },
  {
    label: STRINGS.LABELS.PAST_WINNERS,
    value: "2",
    path: ROUTE_CONSTANTS.PAST_WINNERS,
  },
  {
    label: STRINGS.LABELS.GIVE2_THE_KIDS_NEWS,
    value: "3",
    path: ROUTE_CONSTANTS.GIVE2THEKIDS_BLOGS,
  },
];

export const FOOTER_TAB = [
  {
    label: STRINGS.LABELS.CHAT_WITH_US,
    value: "1",
    // path: ROUTE_CONSTANTS.CHA
  },
  // {
  //   label: STRINGS.LABELS.TERMS_AND_CONDITION,
  //   value: '2',
  //   path: ROUTE_CONSTANTS.TERM_AND_CONDITIONS,
  // },
  // {
  //   label: STRINGS.LABELS.PRIVACY_POLICY,
  //   value: '3',
  //   path: ROUTE_CONSTANTS.PRIVACY_POLICY_PAGE
  // },
  // {
  //   label: STRINGS.LABELS.CONTACT_US,
  //   value: '3',
  //   path: ROUTE_CONSTANTS.CONTACT_US
  // }
];

export const SEE_PRIZE_SHOWCASE_LINK =
  process.env.REACT_APP_DOMAIN_MINIHEARTS_ORG + "/prize-pool";
