import { ROUTE_CONSTANTS } from "Shared";
import Maintenance from "Views/Maintenance";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const AppLayout = ({ isAuthenticated, children }) => {

    const [isOffline, setIsOffline] = useState(navigator.onLine);
	const location = useLocation();

    useEffect(() => {
		const handleOffline = () => {
			setIsOffline(true);
		};

		const handleOnline = () => {
			setIsOffline(false);
		};

		window.addEventListener('offline', handleOffline);
		window.addEventListener('online', handleOnline);

		return () => {
			window.removeEventListener('offline', handleOffline);
			window.removeEventListener('online', handleOnline);
		};
    }, []);
	console.log(isOffline, "Internet connection lost. Please check your network connection.");
  	
	return (
    <>
  
			{location?.pathname !== ROUTE_CONSTANTS?.MAINTENANCE ? (
				<>
					{children}
				</>
			) : (
				<Maintenance />
			)}

    </>
  );
};

export default AppLayout;
