
//hooks
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, useRoutes } from "react-router-dom";

//components
import AppLayout from "Components/Core/AppLayout";
import PrivateLayout from "Components/Core/PrivateLayout";
import PublicLayout from "Components/Core/PublicLayout";
import DocumentTitle from "./DocumentTitle";

//Utils
import { ROUTE_CONSTANTS } from "Shared";
import { updateAuthToken } from "Shared/Axios";
import { AUTH_ROUTES } from "./AuthRoutes";
import { PRIVATE_ROUTES } from "./PrivateRoutes";
import { PUBLIC_ROUTES } from "./PublicRoutes";

const DEFAULT_AUTHENTICATED_ROUTE = ROUTE_CONSTANTS.HOME;
const DEFAULT_GUEST_ROUTE = ROUTE_CONSTANTS.HOME;

const GuestRoutes = () => {
  const routes = AUTH_ROUTES.concat(PUBLIC_ROUTES);
  let defaultGuestRoute = {
    path: "*",
    element: <Navigate to={DEFAULT_GUEST_ROUTE} replace />,
    title: "Home",
  };
  routes.push(defaultGuestRoute);
  const routing = useRoutes(routes);
  return <PublicLayout>{routing}</PublicLayout>;
};

const AuthenticatedRoutes = () => {
  const routes = PUBLIC_ROUTES.concat(PRIVATE_ROUTES);
  let defaultRoute = {
    path: "*",
    element: <Navigate to={DEFAULT_AUTHENTICATED_ROUTE} replace />,
    title: "Home",
  };
  routes.push(defaultRoute);
  const routing = useRoutes(routes);
  return <PrivateLayout>{routing}</PrivateLayout>;
};

const RootRouter = () => {
  const token = useSelector((state) => state.auth.token);
  updateAuthToken(token);
  const baseName = process.env.REACT_APP_BASE_NAME;
  const isAuthenticated = !!token;
  return (
    <BrowserRouter basename={baseName}>
      <DocumentTitle isAuthenticated={isAuthenticated} />
      <AppLayout isAuthenticated={isAuthenticated}>{false ? <AuthenticatedRoutes /> : <GuestRoutes />}</AppLayout>
    </BrowserRouter>
  );
};

export default RootRouter;
