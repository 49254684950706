// components
import { Link } from "react-router-dom";

// constants
import { DONATION_PAGE_URL, Images, STRINGS } from "Shared";

// styles
import './style.scss';

function ComingSoon() {
  return (
    <section className="herobanner position-relative">
      <figure className="herobanner_card">
        <img
          src={Images.HeroImage}
          className="w-100"
          width="1000"
          height="700"
          alt="banner-images"
        />
        <div className="herobanner_card_box ">
          <div className="position-relative herobanner_card_content">
            <h1 className="h1"> {STRINGS.COMING_SOON} </h1>
            <Link
              className="text-center mt-md-5 mt-5 d-flex justify-content-center align-items-center animating_logo"
              type="link"
              aria-current="page"
              target="_self"
              to={DONATION_PAGE_URL}
            >
              <img
                src={Images.DonateNow}
                className="img-fluid donateNow"
                width="100"
                alt="Donate Now heart"
              />
            </Link>
          </div>
        </div>
      </figure>
    </section>
  );
}

export default ComingSoon;
