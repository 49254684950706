// components

export const PublicLayout = ({ children }) => {

	return (
		<>
			{/* ------- First Header Component ------- */}
			{/* <FirstHeader /> */}

				<main className="main">

					{/* ------- Children Component ------- */}
					{children}

				</main>
			{/* ------- Footer Component ------- */}
			{/* <Footer /> */}
		</>
	);
};

export default PublicLayout;