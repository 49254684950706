export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_BASE_URL_LUXE = process.env.REACT_APP_API_BASE_LUXE_URL;
export const X_API_KEY = process.env.REACT_APP_LUXE_X_API_KEY;

export const API_URLS = {
  ONE_TIME_ANONYMOUS_PAYMENT: `/stripe/donation/oneTimeAnonymousPayment`,
  SUBSCRIPTION_PAYMENT: `/stripe/donation/oneTimeSubscription`,
  GET_TICKETS: "/v1/game",
  PAST_WINNER: "/v1/game/lotteryWinners",
  GET_RAFFLE_TICKET: "/constants/raffleticket",
  EMAIL_RAFFLE_TICKET: "/v1/lotto/tickets",
  CHECK_EMAIL: "/user/checkEmailOrPhone",
  GAME_WITH_WINNER_TICKETS: "v1/game/lottery/pastWinners",
  GET_GIVE2_THE_KIDS_BLOGS: "/v1/game/blogs",
  VALIDATE_BONUS_CODE: "/give2kids/referralcode/validate",
  BLOGS_CATEGORY: "/v1/game/blogCategory",
  CONTACT_US: "/user/contactEmail"
};

export const STATUS_CODES = {
  SUCCESS: 200,
  CREATED: 201,
  UNAUTHORIZED: 401,
  BAD_REQUEST: 400,
};
